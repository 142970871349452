import React from 'react';
import {ThemeProvider} from 'styled-components';

import themes from '../commonStyles/themes';
import { MainContainer, ThemeContainer } from '../commonStyles/Containers';

import AllFixedElements from '../components/AllFixedElements';

import SEO from '../components/seo';

import SectionInfoCaseStudy from '../componentsCaseStudy/SectionInfoCaseStudy';
import SectionImageCaseStudy from '../componentsCaseStudy/SectionImageCaseStudy';
import SectionDescCaseStudy  from '../componentsCaseStudy/SectionDescCaseStudy';
import SectionPaletteCaseStudy from '../componentsCaseStudy/SectionPaletteCaseStudy';
import SectionImagesTiles from '../componentsCaseStudy/SectionImagesTiles';
import SectionLastCaseStudy from '../componentsCaseStudy/SectionLastCaseStudy'

import GlobalStyle from '../components/layoutCss';
import SectionFooter from '../components/SectionFooter';

import SequelContent from '../data/SequelContent';


class SequelCaseStudy extends React.PureComponent {

  render() {
    return (
      <>
        <GlobalStyle />
        <ThemeProvider theme={themes.themeMain}>
          <ThemeContainer>
          <>
            <SEO
              title='Team Sequel'
              path={this.props.path}
            />
            <AllFixedElements
              isCaseStudy={true}
            />
            <MainContainer>
                <SectionInfoCaseStudy
                  topdesc1={SequelContent.topdesc1}
                  topdesc2={SequelContent.topdesc2}
                  projectName={SequelContent.projectName}
                  projectCategories1={SequelContent.projectCategories1}
                  projectCategories2={SequelContent.projectCategories2}
                  projectDeliverables1={SequelContent.projectDeliverables1}
                  projectDeliverables2 ={SequelContent.projectDeliverables2}
                />
                <SectionImageCaseStudy bgImg={SequelContent.HeadImage}/>

                {/*----------------------------Identity----------------------------*/}

                <SectionDescCaseStudy
                  descCounter ={'01'}
                  descHeader={SequelContent.descHeader1}
                  descParagraph={SequelContent.descParagraph1}
                />
                <SectionPaletteCaseStudy myColorPalette={SequelContent.colorPalette}/>
                <SectionImageCaseStudy bgImg={SequelContent.ImageBranding} />
                <SectionImagesTiles myArray={SequelContent.GalleryBranding}/>
                <SectionImagesTiles myArray={SequelContent.GalleryBranding2}/>
                <SectionImageCaseStudy bgImg={SequelContent.ImageBranding2} />

                <SectionLastCaseStudy />
                <SectionFooter />
            </MainContainer>
          </>
        </ThemeContainer>
        </ThemeProvider>
      </>
    );
  }
}

export default SequelCaseStudy;
