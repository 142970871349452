import logo from '../images/projects/sequel/logo.png';
import logotype from '../images/projects/sequel/logotype.png';
import cards from '../images/projects/sequel/cards.png';
import brand from '../images/projects/sequel/brand.png';
import brand2 from '../images/projects/sequel/sequel_1.jpg';
import brand3 from '../images/projects/sequel/sequel_2.jpg';
import brand4 from '../images/projects/sequel/sequel_3.jpg';

const MalvaPalette = [
  { id: 1, colorCode: '#324DD9', colorName: 'New car', isDark: true },
  { id: 2, colorCode: '#EC6133', colorName: 'Carrot orange' },
  { id: 3, colorCode: '#FFD6C9', colorName: 'Unbleached silk' },
  { id: 4, colorCode: '#8F9EEA', colorName: 'Light cobalt blue' },
  { id: 5, colorCode: '#FFFFFF', colorName: 'White' },
  { id: 6, colorCode: '#F7F8FF', colorName: 'Ghost white' },
  { id: 7, colorCode: '#23262A', colorName: 'Dark Gunmetal', isDark: true },
  { id: 8, colorCode: '#0C0B0B', colorName: 'smoky black', isDark: true }
];

export const GallerySequelLogo = [
  { id: 1, src: logo },
  { id: 2, src: logotype }
];

export const GallerySequelBranding = [
  { id: 1, src: brand2 },
  { id: 2, src: brand3 }
];


export default {
  colorPalette: MalvaPalette,
  HeadImage: brand4,
  projectName: 'Team Sequel',
  projectCategories1:'Visual identity',
  websiteLink: 'https://teamsequel.io/',
  websiteName: 'teamsequel.io',
  projectCategories2:'',
  projectDeliverables1:'Brand identity',
  projectDeliverables2:'Online and offline marketing materials',
  topdesc1: 'Team Sequel is London-based event management company.',
  descHeader1:'What we did',
  descParagraph1: ' To fully reflect the company values and vibe, I have selected a palette of fresh, modern colors, delivered a bold logotype, business cards, stationery and some offline and online assets.',
  ImageBranding: brand,
  GalleryBranding: GallerySequelLogo,
  GalleryBranding2: GallerySequelBranding,
  ImageBranding2: cards,

};
